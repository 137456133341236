<template>
  <div class="collectionPage">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">我的喜欢</div>
      <div class="rightBtn">
        <div class="edit" @click="edit">
          {{ isEdit ? '取消' : '编辑' }}
        </div>
      </div>
    </div>

    <van-tabs
      v-model="activeName"
      @change="changeTab"
      background="#fff"
      title-active-color="rgb(255,103,143)"
      title-inactive-color="rgb(51,51,51)"
      class="vant_tabs"
      animated
      :swipeable="true"
    >
      <van-tab :title="item.name" v-for="item in tabs" :key="item.path" :name="item.path">
        <keep-alive>
          <router-view ref="currentView" v-if="$route.path == item.path" @close="close" />
        </keep-alive>
      </van-tab>
    </van-tabs>
    <div class="delBtnBox" :class="{ activeDelBtnBox: isEdit }">
      <div class="selectAll" @click="selectAll">
        <svg-icon class="editBtn" iconClass="batchSelected" v-if="isSelectAll" />
        <svg-icon class="editBtn" iconClass="batchUnSelect" v-else />
        <span>全选</span>
      </div>
      <div class="verticalLine1"></div>
      <div class="del" @click="delBtn">
        <span>删除</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Tab, Tabs } from 'vant';
export default {
  name: 'myCollection',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  data() {
    return {
      activeName: '/myCollection/collecMovieList',
      tabs: [
        {
          refStr: 'movieView',
          path: '/myCollection/collecMovieList',
          name: '影视',
        },
        {
          path: '/myCollection/collecVideoList',
          name: '抖音',
        },
        {
          path: '/myCollection/collecPostList',
          name: '图文',
        },
        {
          path: '/myCollection/collecTopic',
          name: '话题',
        },
      ],
      isEdit: false,
      isSelectAll: false, // 是否全选
    };
  },
  created() {
    this.activeName = this.$route.path;
  },
  methods: {
    // 切换tab
    changeTab(name) {
      this.isEdit = false;
      if (this.$refs.currentView.length > 0) {
        this.$refs.currentView[0].changeEditSta(false);
      }
      this.$router.replace(name);
    },
    //编辑
    edit() {
      if (this.$refs.currentView[0].list.length == 0) return;
      this.isEdit = !this.isEdit;
      this.$refs.currentView[0].changeEditSta(this.isEdit);
    },
    // 选择全部
    selectAll() {
      this.isSelectAll = !this.isSelectAll;
      this.$refs.currentView[0].selectAll(this.isSelectAll);
    },
    // 删除
    delBtn() {
      this.$refs.currentView[0].batchDel();
    },
    close() {
      this.isEdit = false;
      this.$refs.currentView[0].changeEditSta(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.collectionPage {
  height: 100%;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;

    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
      display: flex;
      justify-content: flex-end;
      .edit {
        padding: 5px;
      }
    }
  }
  .vant_tabs {
    height: 100%;
    /deep/ .van-tabs__wrap {
      height: 40px;
      margin: 0 auto 10px;
    }
    /deep/ .van-tab__text {
      font-size: 18px;
    }
    /deep/ .van-tabs__nav {
      // width: 311px;
      // height: 28px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
    /deep/ .van-tab {
      width: 56px;
    }
    /deep/ .van-tabs__line {
      width: 20px;
      height: 4px;
      background: rgb(255, 103, 143);
    }
    /deep/ .van-tab__pane {
      height: 100%;
    }
    /deep/ .van-tab__pane-wrapper {
      height: 100%;
    }
    /deep/ .van-tabs__track {
      height: 100%;
    }
    /deep/ .van-tabs__content {
      height: 100%;
    }
  }
  .delBtnBox {
    height: 60px;
    width: 100%;
    position: fixed;
    left: 0px;
    bottom: 0px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    transform: translateY(60px);
    transition: transform 0.5s;
    .selectAll {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      .editBtn {
        width: 22px;
        height: 22px;
      }
      span {
        margin-left: 5px;
      }
    }
    .verticalLine1 {
      height: 35px;
      width: 1px;
      background-color: rgb(205, 212, 223);
    }
    .del {
      flex: 1;
      color: rgb(255, 103, 143);
      text-align: center;
    }
  }
  .activeDelBtnBox {
    transform: translateY(0px);
    transition: transform 0.3s;
  }
}
</style>
